<template>
  <div class="paper_list_warp">
    <div class="top_input">
      <a-input placeholder="请输入试卷名称"
               @keydown.enter="getPaperList"
               v-model:value="keywords">
        <template #suffix>
          <search-outlined @click="getPaperList" />
        </template>
      </a-input>
      <a-select placeholder="请选择"
                v-model:value="type"
                @change="getPaperList"
                :options='paperTypeList.map((item,i) => ({ label: item.type_name,value:item.type_value }))'>
      </a-select>

    </div>
    <div class="paperlist">
      <div class="paper"
           v-for="(item,index) in paperList"
           @click="toInfo(item)"
           :key="index">
        <div class="top">
          <div>
            {{item.source_name}}
          </div>
          <div>
            {{item.user_done}}
          </div>
          <div>
            难度系数：{{item.difficulty}}
          </div>
        </div>
        <div class="bottom">
          <div class="btn">
            开始测试
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { getWholePaperList, getPaperType } from '@/api/paper.js'
import { useRoute, useRouter } from "vue-router";
import { SearchOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
let keywords = ref('')
let paperList = ref([])
let paperTypeList = ref([])
let route = useRoute()
let router = useRouter()
let type = ref('')
onMounted(() => {
  getPaperList()
  getPaperTypeList()
})
const getPaperTypeList = async () => {
  const { data } = await getPaperType()
  paperTypeList.value = data
}
const getPaperList = async () => {
  let params = {
    subject_id: route.query.subjectId,
    paper_district_id: route.query.id,
    keywords: keywords.value,
    type: type.value
  }

  const { data } = await getWholePaperList(params)
  paperList.value = data.list
  if (paperList.value.length == 0) {
    message.warn('暂无数据')
  }

}

const toInfo = (row) => {
  let form = {
    source_id: row.source_id,
    source_year: row.source_year,
    is_true_paper: row.is_true_paper
  }
  window.sessionStorage.removeItem('testpaperCardIndex')
  window.sessionStorage.removeItem('paper')
  window.sessionStorage.removeItem('images')
  router.push('/doPaper?form=' + JSON.stringify(form))
}

</script>

<style lang="scss" scoped>
.paper_list_warp {
  width: 100%;
  height: 100vh;
  background-color: #f6f6f6;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20rem;

  .top_input {
    margin-top: 40rem;
    margin-left: 50rem;
    .ant-input-affix-wrapper {
      width: 550rem;
      height: 54rem;
      box-shadow: 0rem 2rem 8rem 0rem rgba(0, 0, 0, 0.09);
      border-radius: 30rem;
      font-size: 24rem;
      text-indent: 30rem;
    }
    .ant-select {
      width: 550rem;
      height: 54rem;
      line-height: 54rem;
      border: 1px solid #ddd;
      box-shadow: 0rem 2rem 8rem 0rem rgba(0, 0, 0, 0.09);
      border-radius: 30rem;
      background-color: #fff;
      font-size: 24rem;
      text-indent: 30rem;
      overflow: hidden;
      margin-left: 40rem;
      vertical-align: bottom;
    }
    :deep .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 0;
    }
    :deep .ant-select-selector {
      width: 90%;
      height: 54rem;
      line-height: 54rem;
    }
    :deep .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      height: 54rem;
      line-height: 54rem;
    }
    :deep .ant-select-arrow {
      right: 80rem;
    }
    :deep .ant-select-single .ant-select-selector .ant-select-item {
      font-size: 24rem !important;
      line-height: 33rem !important;
    }
  }
  .paperlist {
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - 70rem);
    margin: 0 20rem 0 50rem;
    .paper {
      background-color: white;
      width: 340rem;
      position: relative;
      // height: 236rem;
      overflow: hidden;
      box-shadow: 0rem 2rem 8rem 0rem rgba(0, 0, 0, 0.09);
      border-radius: 20rem;
      margin-right: 30rem;
      margin-top: 30rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        font-size: 22rem;
        font-weight: bold;
        color: #000000;
        // display: flex;
        margin: 15rem;
        // height: 130rem;
      }
      .bottom {
        height: 70rem;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        .btn {
          cursor: pointer;
          font-size: 22rem;
          width: 150rem;
          height: 44rem;
          background: #2196f3;
          border-radius: 30rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
        }
      }
    }
  }
}
</style>